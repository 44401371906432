import styled from 'styled-components'
import { colors, media } from 'themes'

export const Number = styled.div`
  position: relative;
  z-index: 1;
  color: ${colors.cleanGrey};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin-bottom: 16px;
`

export const Text = styled.div`
  position: relative;
  z-index: 1;
  color: black;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  padding-right: 35px;

  ${({ wider }) => wider && `
    padding-right: 25px;
  `}
`

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 55px;

  ${media.sm`
    margin-bottom: 40px;
  `}

  &:hover {
    cursor: default;
    
    &:after {
      content: '';
      position: absolute;
      top: -20px;
      left: -30px;
      width: calc(100% + 30px);
      height: calc(100% + 32px);
      box-shadow: 0 4px 8px -4px #5D92DC;
      border-radius: 5px;
      background: ${colors.mainBlue};

      ${media.xl`
        width: 100%;
      `}

      ${media.md`
        display: none;
      `}
    }

    ${Number} {
      color: #00A8E5;

      ${media.md`
        color: ${colors.cleanGrey};
      `}
    }

    ${Text} {
      color: white;

      ${media.md`
        color: black;
      `}
    }
  }
`