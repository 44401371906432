import React, { Component } from 'react'
import { Row, Col } from 'styled-bootstrap-grid'
import Container from 'components/shared/Container'
import { Wrapper, Button, Content, Circles } from './styled'

class ContactLink extends Component {
  state = {
    hovered: false,
  }

  handleHoverChange = () => {
    this.setState((prevState) => ({
      hovered: !prevState.hovered,
    }));
  }

  render() {
    return (
      <Wrapper>
        <Circles big={this.state.hovered} />

        <Container>
          <Row>
            <Col md={10} mdOffset={1} lg={8} lgOffset={2} xl={6} xlOffset={3}>
              <Content>
                Looking for reliable construction company for your future
                project?
              </Content>

              <Button to="/contact" onMouseEnter={this.handleHoverChange} onMouseLeave={this.handleHoverChange}>Contact us!</Button>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default ContactLink
