import styled from 'styled-components'
import { colors } from 'themes'
import { Row } from 'styled-bootstrap-grid'
import { media } from 'themes'

export const ServicesWrapper = styled.section`
  margin-top: 55px;
  margin-bottom: 150px;

  ${media.sm`
    margin-top: 0;
  `}
`

export const Divider = styled.div`
  width: 135px;
  height: 1px;
  background-color: ${colors.mutedWhite};
  margin-bottom: 63px;
`
export const RelativeRow = styled(Row)`
  position: relative;
`

export const VerticalPane = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  width: 70px;
  height: 609px;
  background: linear-gradient(0deg, rgba(0, 168, 229, 0) 0%, #00a8e5 100%);

  ${media.md`
    display: none;
  `}
`
