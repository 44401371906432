import styled from 'styled-components'
import { Link } from 'gatsby'
import FileCircles from 'images/contactLinkCircles.svg'
import { colors, media } from 'themes'

export const Wrapper = styled.div`
  position: relative;
  background: ${colors.mainBlue};
  padding: 80px 0 100px;
  text-align: center;
  color: white;
  font-size: 36px;
  line-height: 48px;
  overflow: hidden;

  ${media.sm`
    padding: 100px 0;
    font-size: 28px;
    line-height: 40px;
  `}
`

export const Circles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${FileCircles}) center center no-repeat;
  transition: transform 200ms; 

  ${({ big }) => big && `
    transform: scale(1.2);
  `}
`

export const Button = styled(Link)`
  display: inline-block;
  background: ${colors.mainYellow};
  padding: 15px 41px 13px 41px;;
  color: white;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  text-decoration: none;
  margin-top: 40px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`

export const Content = styled.div`
  ${media.xl`
    padding: 0 100px;
  `}
`