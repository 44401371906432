import styled from 'styled-components';
import { colors, media } from 'themes';

const Text = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
  line-height: 32px;
  margin-bottom: 32px;
  color: ${colors.lighterGrey};

  strong {
    font-weight: bold;
  }

  ${({ main }) => main && `
    font-weight: bold;
  `}

  ${media.xl`
    padding-right: 30px;
  `}
`;

export default Text;
