import styled from 'styled-components'
import { media } from 'themes'

const SectionTitle = styled.h3`
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
  margin-bottom: 50px;

  ${({ main }) => main && `
    margin-top: 92px;
    margin-bottom: 80px;
  `}

  ${media.sm`
    font-size: 28px;
    line-height: 33px;
    
    ${({ main }) => main && `
      margin-bottom: 32px;
    `}
  `}
`

export default SectionTitle
