import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'

import SectionTitle from 'components/shared/SectionTitle'
import Text from 'components/shared/Text'
import Container from 'components/shared/Container'

import Service from './components/Service'
import { ServicesWrapper, Divider, RelativeRow, VerticalPane } from './styled'

const Services = () => (
  <>
    <Container>
      <Row>
        <Col lg={12}>
          <SectionTitle main>Services</SectionTitle>
        </Col>
      </Row>
    </Container>

    <Container>
      <RelativeRow>
        <Col lg={6}>
          <Text>
            Metro-Tech Erectors offers customized solutions for commercial,
            industrial, and residential window and façade installation
            projects.&nbsp;
            <strong>Our range of work includes:</strong>
          </Text>
        </Col>

        <VerticalPane />
      </RelativeRow>
      <ServicesWrapper>
        <Row>
          <Service
            number="01"
            text="Façade new construction and replacement"
            wider
          />
          <Service number="02" text="Curtain walls, Window walls" />
          <Service number="03" text="Ornamental and metal works" />
        </Row>
        <Row>
          <Col lg={12}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Service number="04" text="Steel glass and all glass designs" />
          <Service number="05" text="Windows, Doors, Entrances" />
          <Service number="06" text="Storefronts" />
          <Service number="07" text="Point Glazing walls" featured />
          <Service number="08" text="Fin walls" />
          <Service number="09" text="Skylights" />
          <Service number="10" text="Channel Glass systems" />
          <Service number="11" text="Glass and metal Railings" />
          <Service number="12" text="Canopies" />
          <Service number="13" text="Metal and Composite Panels" />
          <Service number="14" text="Terracotta walls" />
        </Row>
      </ServicesWrapper>
    </Container>
  </>
)

export default Services
