import React from 'react'
import Layout from 'components/shared/Layout'

import Services from 'components/Services/Services';
import ContactLink from 'components/shared/ContactLink'

const About = () => (
  <Layout>
    <Services />
    <ContactLink />
  </Layout>
)

export default About
