import React from 'react'
import { Col } from 'styled-bootstrap-grid'
import { Wrapper, Number, Text } from './styled'

const Service = ({ number, text, wider, featured }) => (
  <Col md={4} lg={3}>
    <Wrapper featured={featured}>
      <Number featured={featured}>{number}</Number>
      <Text wider={wider} featured={featured}>
        {text}
      </Text>
    </Wrapper>
  </Col>
)

export default Service
